// export const getVisitorInfo = async () => {
//     try {
//         const response = await fetch("https://ipapi.co/json/");
//         if (!response.ok) {
//             throw new Error(`Failed to fetch visitor info. Status: ${response.status}`);
//         }
//         const data = await response.json();

//         return {
//             ip: data.ip,
//             country: data.country_name,
//             city: data.city,
//             region: data.region,
//             isp: data.org,
//             timezone: data.timezone,
//             network: data.network,
//             latitude: data.latitude,
//             longitude: data.longitude,
//             utc_offset: data.utc_offset,
//             country_code: data.country_code,
//             asn: data.asn,
//         };
//     } catch (error) {
//         console.error("❌ Error fetching visitor info:", error);
//         return null;
//     }
// };


// export const getVisitorInfo = async () => {
//     try {
//         // First, get IP-based information
//         const response = await fetch("https://ipapi.co/json/");
//         if (!response.ok) {
//             throw new Error(`Failed to fetch visitor info. Status: ${response.status}`);
//         }
//         const ipData = await response.json();
        
//         // Create base visitor info from IP data
//         const visitorInfo = {
//             ip: ipData.ip,
//             country: ipData.country_name,
//             city: ipData.city,
//             region: ipData.region,
//             isp: ipData.org,
//             timezone: ipData.timezone,
//             network: ipData.network,
//             latitude: ipData.latitude,
//             longitude: ipData.longitude,
//             utc_offset: ipData.utc_offset,
//             country_code: ipData.country_code,
//             asn: ipData.asn,
//             locationSource: "ip", // Default source is IP-based
//         };
        
//         // Try to get more accurate location using browser's Navigator API
//         if (navigator.geolocation) {
//             try {
//                 // Wrap the geolocation API in a promise
//                 const navigatorPosition = await new Promise((resolve, reject) => {
//                     navigator.geolocation.getCurrentPosition(
//                         position => resolve(position),
//                         error => reject(error),
//                         { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
//                     );
//                 });
                
//                 // Update location with more accurate Navigator data if available
//                 visitorInfo.latitude = navigatorPosition.coords.latitude;
//                 visitorInfo.longitude = navigatorPosition.coords.longitude;
//                 visitorInfo.accuracy = navigatorPosition.coords.accuracy;
//                 visitorInfo.locationSource = "navigator"; // Update source to navigator
                
//                 // Optional: include more details if available
//                 if (navigatorPosition.coords.altitude !== null) {
//                     visitorInfo.altitude = navigatorPosition.coords.altitude;
//                 }
//                 if (navigatorPosition.coords.heading !== null) {
//                     visitorInfo.heading = navigatorPosition.coords.heading;
//                 }
//                 if (navigatorPosition.coords.speed !== null) {
//                     visitorInfo.speed = navigatorPosition.coords.speed;
//                 }
//             } catch (geoError) {
//                 // If navigator geolocation fails, we still have the IP-based location
//                 console.warn("Navigator geolocation failed:", geoError.message);
//                 // Keep using the IP-based location in visitorInfo
//             }
//         }
        
//         return visitorInfo;
//     } catch (error) {
//         console.error("❌ Error fetching visitor info:", error);
//         return null;
//     }
// };

import UAParser from "ua-parser-js";

export const getVisitorInfo = async () => {
    try {
        // Fetch IP data and user agent simultaneously
        const [ipResponse, userAgent] = await Promise.all([
            fetch("https://ipapi.co/json/"),
            navigator.userAgent
        ]);

        // Check if the IP API response is successful
        if (!ipResponse.ok) {
            throw new Error(`Failed to fetch visitor info. Status: ${ipResponse.status}`);
        }

        // Parse IP data
        const ipData = await ipResponse.json();

        // Parse user agent data
        const parser = new UAParser(userAgent);
        const browserInfo = parser.getBrowser();
        const osInfo = parser.getOS();
        const deviceInfo = parser.getDevice();

        // Initialize visitor info object
        const visitorInfo = {
            ip: ipData.ip,
            country: ipData.country_name,
            city: ipData.city,
            region: ipData.region,
            isp: ipData.org,
            timezone: ipData.timezone,
            network: ipData.network,
            latitude: ipData.latitude,
            longitude: ipData.longitude,
            utc_offset: ipData.utc_offset,
            country_code: ipData.country_code,
            asn: ipData.asn,
            locationSource: "ip",
            userAgent,
            browser: browserInfo.name || "Unknown",
            os: osInfo.name || "Unknown",
            device: deviceInfo.type || "Desktop"
        };

        // Attempt to get geolocation if supported
        if (navigator.geolocation) {
            try {
                const position = await getGeolocation();
                visitorInfo.latitude = position.coords.latitude;
                visitorInfo.longitude = position.coords.longitude;
                visitorInfo.accuracy = position.coords.accuracy;
                visitorInfo.locationSource = "navigator";

                // Optional geolocation data
                if (position.coords.altitude !== null) visitorInfo.altitude = position.coords.altitude;
                if (position.coords.heading !== null) visitorInfo.heading = position.coords.heading;
                if (position.coords.speed !== null) visitorInfo.speed = position.coords.speed;
            } catch (geoError) {
                console.warn("Navigator geolocation failed:", geoError.message);
                console.warn("Using IP-based location as fallback.");
            }
        }

        return visitorInfo;
    } catch (error) {
        console.error("❌ Error fetching visitor info:", error);
        return null;
    }
};

// Helper function to get geolocation
const getGeolocation = () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            resolve,
            reject,
            { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
        );
    });
};
