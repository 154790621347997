// // src/services/discordService.js
// export const sendToDiscord = async (visitorInfo) => {
//     const webhookUrl = "https://discord.com/api/webhooks/1344854121999499306/oNirMHlFlvW3HE7WgEQZ15Xfln_vEobqKena-tRJjStCsvr-JxLEgXIA15U_ZxmlPBWa";
  
//     const message = {
//       content: `New visitor! IP: ${visitorInfo.ip}, Country: ${visitorInfo.country}`,
//     };
  
//     try {
//       const response = await fetch(webhookUrl, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(message),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to send message to Discord');
//       }
//     } catch (error) {
//       console.error('Error sending message to Discord:', error);
//     }
//   };


// src/services/discordService.js

// export const sendToDiscord = async (visitorInfo) => {
//     const webhookUrl = "https://discord.com/api/webhooks/1344854121999499306/oNirMHlFlvW3HE7WgEQZ15Xfln_vEobqKena-tRJjStCsvr-JxLEgXIA15U_ZxmlPBWa";
//     const googleMapsUrl = `https://www.google.com/maps?q=${visitorInfo.latitude},${visitorInfo.longitude}`;

//     const embed = {
//         title: "🌍 New Visitor Alert",
//         color: 0x3498db, // Blue
//         fields: [
//             { name: "🔢 IP Address", value: `\`${visitorInfo.ip}\``, inline: true },
//             { name: "🗺️ Country", value: `\`${visitorInfo.country} (${visitorInfo.country_code})\``, inline: true },
//             { name: "🏙️ City", value: `\`${visitorInfo.city}, ${visitorInfo.region} \``, inline: true },
//             { name: "📶 ISP", value: `\`${visitorInfo.isp} (ASN: ${visitorInfo.asn})\``, inline: true },
//             { name: "🕒 Timezone", value: `\`${visitorInfo.timezone} (UTC ${visitorInfo.utc_offset})\``, inline: true },
//             { name: "🌐 Network", value: `\`${visitorInfo.network}\``, inline: true },
//             { name: "🗺️ Location", "value": `[🗺️ View on Google Maps](${googleMapsUrl})`, "inline": false }
//         ],
//         timestamp: new Date().toISOString()
//     };

//     const payload = { embeds: [embed] };

//     try {
//         const response = await fetch(webhookUrl, {
//             method: "POST",
//             headers: { "Content-Type": "application/json" },
//             body: JSON.stringify(payload),
//         });

//         if (!response.ok) {
//             const errorText = await response.text();
//             throw new Error(`Failed to send message. Status: ${response.status}, Response: ${errorText}`);
//         }

//         // console.log("✅ Visitor info successfully sent to Discord:", visitorInfo);
//     } catch (error) {
//         console.error("❌ Error sending visitor info:", error);
//     }
// };

export const sendToDiscord = async (visitorInfo) => {
    const webhookUrl = "https://discord.com/api/webhooks/1344854121999499306/oNirMHlFlvW3HE7WgEQZ15Xfln_vEobqKena-tRJjStCsvr-JxLEgXIA15U_ZxmlPBWa";
    const googleMapsUrl = `https://www.google.com/maps?q=${visitorInfo.latitude},${visitorInfo.longitude}`;

    const embed = {
        title: "🌍 New Visitor Alert",
        color: 0x3498db, // Blue
        fields: [
            { name: "🔢 IP Address", value: `\`${visitorInfo.ip}\``, inline: true },
            { name: "🗺️ Country", value: `\`${visitorInfo.country} (${visitorInfo.country_code})\``, inline: true },
            { name: "🏙️ City", value: `\`${visitorInfo.city}, ${visitorInfo.region} \``, inline: true },
            { name: "📶 ISP", value: `\`${visitorInfo.isp} (ASN: ${visitorInfo.asn})\``, inline: true },
            { name: "🕒 Timezone", value: `\`${visitorInfo.timezone} (UTC ${visitorInfo.utc_offset})\``, inline: true },
            { name: "🌐 Network", value: `\`${visitorInfo.network}\``, inline: true },
            { name: "🗺️ Location", value: `[View on Google Maps](${googleMapsUrl})`, inline: false },
            { name: "🖥️ Browser", value: `\`${visitorInfo.browser}\``, inline: true },
            { name: "💻 OS", value: `\`${visitorInfo.os}\``, inline: true },
            { name: "📱 Device", value: `\`${visitorInfo.device}\``, inline: true },
            { name: "🛠️ Location Source", value: `\`${visitorInfo.locationSource}\``, inline: true },
            { name: "📄 User-Agent", value: `\`${visitorInfo.userAgent}\``, inline: false }
        ],
        timestamp: new Date().toISOString()
    };

    const payload = { embeds: [embed] };

    try {
        const response = await fetch(webhookUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to send message. Status: ${response.status}, Response: ${errorText}`);
        }
    } catch (error) {
        console.error("❌ Error sending visitor info:", error);
    }
};
