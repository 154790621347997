import React, { useEffect } from "react";
import { getVisitorInfo } from '../../services/visitorService'
import { sendToDiscord } from '../../services/discordService';

  // const [country, setCountry] = useState(null);
  // const [flagUrl, setFlagUrl] = useState(null);

  // useEffect(() => {
  //   const fetchData = () => {
  //     fetchCountry()
  //       .then((countryData) => {
  //         setCountry(countryData?.country_name);
  //         return fetchFlag(countryData?.country_code?.toLowerCase());
  //       })
  //       .then((flag) => {
  //         setFlagUrl(flag);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error.message);
  //       });
  //   };
  //   fetchData();
  // }, []);

  // const fetchCountry = () => {
  //   const url = "https://ipapi.co/json/";
  //   return fetch(url)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Error fetching country.");
  //       }
  //       return response.json();
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching country:", error.message);
  //       throw error;
  //     });
  // };

  // const fetchFlag = (countryCode) => {
  //   const url = `https://restcountries.com/v3/alpha/${countryCode}`;
  //   return fetch(url)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Error fetching flag.");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       const flag = data?.[0]?.flags?.[0];
  //       if (!flag) {
  //         throw new Error("Flag data not found.");
  //       }
  //       return flag;
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching flag:", error.message);
  //       throw error;
  //     });
  // };
  const Footer = () => {
    useEffect(() => {
      const handleNewVisitor = async () => {
        const visitorInfo = await getVisitorInfo();
        if (visitorInfo) {
          await sendToDiscord(visitorInfo);
        }
      };
  
      handleNewVisitor();
    }, []);
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="footer-text text-center col-md-12">
            <p>
              Copyright &copy; {new Date().getFullYear()} | All rights reserved.
              <br />
              Made with "<b style={{ color: "red" }}>&hearts;</b>"
            </p>
            {/* <p>
              <span>
                <img
                  src="/assets/nepal_flag.svg"
                  alt="Flag"
                  style={{ width: 30, height: 20, marginRight: 5 }}
                />
              </span>
              {country && flagUrl && (
                <span>
                  <img
                    src={flagUrl}
                    alt="Flag"
                    style={{ width: 30, height: 20, marginRight: 5 }}
                  />
                </span>
              )}
            </p> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
